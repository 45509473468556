import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Popup from 'reactjs-popup';
import SubmissionSplash from '../panels/file_submission/FileSubmission';
import SustLogo from '../assets/Sust_Logo_White_Simple.png'
import Logout from '../routes/logout/Logout'
import {useContext, useEffect, useState} from "react";
import {authContext} from "../context/authContext";
import {CE_MAIN, LOGIN, MAP} from "../constants/routes";
import {useLocalLogin} from "../firebase-config";
import {
    tile_builder_url as server_url,
} from '../config';

// import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

const NavigationBar = ({project, setProject, children}) => {
    const user = useContext(authContext);
    const [superUser, setSuperUser] = useState(false);
    const [role, setRole] = useState('viewer');

    useEffect(() => {
        if (user) {
            user.getIdTokenResult()
                .then((token) => {
                    if (!!token.claims.is_superuser) {
                        setSuperUser(token.claims.is_superuser)
                    } else {
                        console.error('superuser status not available for user')
                    }
                })
                .catch((error) => {
                    console.error(`error while retrieving superuser status: ${error}`)
                });
        }
    }, [user])

    useEffect(() => {
        user && user.getIdTokenResult()
        .then((token) => {
            if (!!token.claims.api_key) {
                fetch(`${server_url}/roles`, {
                    method: 'GET',
                    withCredentials: true,
                    credentials: 'include',
                    headers: {
                        'X-SustGlobal-APIKey': token.claims.api_key,
                        'X-SustGlobal-Project': project,
                    }
                }).then((res) => {
                    return new Promise((resolve) => res.json()
                      .then((json) => resolve({
                        status: res.status,
                        statusText: res.statusText,
                        ok: res.ok,
                        json,
                      })));
                }).then((resp) => {
                    if (resp.ok) {
                        setRole(resp['json'])
                    }
                }).catch((error) => {
                    console.log(error)
                })
            } else {
                console.error('api key not available for user')
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }, [user, project])

    let projects = ProjectsToShow(project, setProject)
    return (<div>
        <div>
            <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
                <Container>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Navbar.Brand className='mb-0 h1'>
                            <img
                                alt="SUST LOGO"
                                src={SustLogo}
                                width="3%"
                                height="auto"
                            />{' '}
                            Climate Explorer - Nature
                        </Navbar.Brand>
                    </Navbar.Collapse>
                    {useMenu(user, superUser, projects, project, role)}
                </Container>
            </Navbar>
        </div>
        {children}
    </div>);
}

const navigateToLink = () => {
    console.log(`Redirecting to ${CE_MAIN}`)
    window.location.replace(CE_MAIN);
}

const navigateToCE = (superUser) => {
    console.log(`useLocalLogin: ${useLocalLogin} - Is super user? ${superUser}`)
    if (useLocalLogin || !superUser) {
        return (<></>)
    }
    return (
        <NavDropdown.Item onClick={navigateToLink}>Climate Explorer</NavDropdown.Item>
    )
}

const ProjectsToShow = (project, setProject) => {
    const user = useContext(authContext);
    const [projects, setProjects] = useState([])

    useEffect(() => {
        user && user.getIdTokenResult()
        .then((token) => {
            if (!!token.claims.api_key) {
                fetch(`${server_url}/superprojects`, {
                    method: 'GET',
                    withCredentials: true,
                    credentials: 'include',
                    headers: {
                        'X-SustGlobal-APIKey': token.claims.api_key,
                    }
                })
                    .then(r => r.json())
                    .then(r => setProjects(r))
            } else {
                console.error('api key not available for user')
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }, [user])

    if (projects.length > 0) {
        if (project === "") {
            if (projects.includes('nc-demo')) {
                setProject('nc-demo')
            } else {
                setProject(projects[0])
            }
        }
        return projects.map( 
            (p, index) => { 
                return (<NavDropdown.Item onClick={() => setProject(p)} id={index}>{p}</NavDropdown.Item>)
            }
        )
    }
    return <></>
}

const useMenu = (user, superUser, projects, project, role) => {
    if (user) {
        return (<Navbar.Collapse id="basic-navbar-nav">
            {
            role !== "viewer" ? 
                 <Nav className="me-auto">
                    <Nav.Item id="create">
                        <Popup trigger={<button className="btn btn-primary"> Create </button>} modal nested>
                            {close => (
                                <div>
                                    <SubmissionSplash project={project}/>
                                </div>
                            )}
                        </Popup>
                    </Nav.Item>
                </Nav>: <></>
            }
            <Nav className="me-auto">
                <NavDropdown title="Project" id="project-dropdown">
                        {projects}
                </NavDropdown>
            </Nav>
            <Nav className="me-auto">
                <Nav.Link href={MAP}>Dashboard</Nav.Link>
            </Nav>
            <Nav className="me-auto">
                <NavDropdown title="Account" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/account/profile">User Profile</NavDropdown.Item>
                    {navigateToCE(superUser)}
                    <NavDropdown.Divider/>
                    <NavDropdown.Item onClick={Logout}>Log Out</NavDropdown.Item>
                </NavDropdown>
            </Nav>
        </Navbar.Collapse>)
    } else {
        return (<Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href={LOGIN}>Login</Nav.Link>
            </Nav>
        </Navbar.Collapse>)
    }
}

export default NavigationBar;