import {Buffer} from 'buffer'


console.log(process.env.REACT_APP_SERVER_URL)
// const personas = JSON.parse(process.env.REACT_APP_PERSONAS)
// console.log(Object.keys(personas))

let auth_url, tile_server_url, tile_builder_url
// if (process.env.REACT_APP_NCS_ENV.includes("local-")) {
//     // auth_url = `http://localhost:3003`
//     // tile_server_url = `http://localhost:3003`
//     tile_builder_url = `http://localhost:3004`
// }
// else {
//     // auth_url = `https://tile-server-${process.env.REACT_APP_NCS_ENV}-4gynfkei6q-uc.a.run.app`
//     // tile_server_url = `https://tile-server-${process.env.REACT_APP_NCS_ENV}-4gynfkei6q-uc.a.run.app`
//     tile_builder_url = process.env.REACT_APP_SERVER_URL
// }
tile_builder_url=process.env.REACT_APP_SERVER_URL

export {auth_url, tile_builder_url, tile_server_url}
export const projects = [
    "Climes_Test"
]
